import './App.css';

import { useEffect, useState } from 'react';

import { withAuthenticator } from '@aws-amplify/ui-react';
import { Storage, Auth, I18n } from 'aws-amplify';

import '@aws-amplify/ui-react/styles.css';

import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Alert from '@mui/material/Alert';


import AWS from 'aws-sdk';

const LAMBDA_FUNCTION_NAME = 'zoomer-ZoomFunctionDocker-4BlBJ16DKfLF';
const AWS_REGION = 'eu-west-2';

// https://ui.docs.amplify.aws/react/connected-components/authenticator/customization
const dictionary = {
  en: {
    'Create Account': 'Create a Free Account',
  },
};
I18n.putVocabularies(dictionary);


async function uploadFile(file) {
  try {
    const result = await Storage.put(file.name, file, {level: 'private', contentType: file.type});
    console.log(result);
  } catch (error) {
    console.error('Error uploading file: ', error);
  }
}



function MyImageList({ itemData, sameSize }) {
  return (
    <>
      {!sameSize && 
        <Box mt={4}>
          <Alert variant="filled" severity="error">Images are not all the same size!</Alert>
        </Box>
      }
      <ImageList>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={item.img}
              srcSet={item.img}
              alt={item.title}
              loading="lazy"
            />
            <ImageListItemBar
              title={item.title}
              // actionIcon={
              //   <IconButton
              //     sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
              //     aria-label={`info about ${item.title}`}
              //   >
              //     <InfoIcon />
              //   </IconButton>
              // }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
}




function App({signOut, user}) {

  const [itemData, setItemData] = useState([]);
  const [zoomValue, setZoomValue] = useState("in");  // default value is 'in'
  const [sameSize, setSameSize] = useState(true);


  function callLambdaFunction() {
    return new Promise(async (resolve, reject) => {
      const credentials = await Auth.currentCredentials();
  
      AWS.config.credentials = new AWS.Credentials({
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
      });
  
      const lambda = new AWS.Lambda({
        region: AWS_REGION,
        httpOptions: {
          timeout: 500 * 1000,
        }
      });
  
      const params = {
        FunctionName: LAMBDA_FUNCTION_NAME,
        InvocationType: 'RequestResponse',
        Payload: JSON.stringify({
          Item: {
            userId: credentials.identityId,
            zoomValue: zoomValue
          }
        })
      };
  
      lambda.invoke(params, function(err, data) {
        if (err) {
          console.log(err, err.stack);
          reject(err);
        } else {
          console.log(data);
          resolve(data);
        }
      });
    });
  }
    
  function handleZoomChange(event) {
    setZoomValue(event.target.value);
    console.log(event.target.value);
  }


  // On component mount, load existing images from S3
  useEffect(() => {
    loadImages();
  }, []);
  

  async function loadImages() {
    try {
      const response = await Storage.list('', {level: 'private'});  // list from the root
      console.log(response);
  
      if (response && Array.isArray(response.results)) {
        const images = response.results.filter(({ key }) => {
          const fileExtension = key.slice(((key.lastIndexOf(".") - 1) >>> 0) + 2);
          console.log('File Extension:', fileExtension);  // Print the file extension
          const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"];
          return imageExtensions.includes(fileExtension);
        });
        console.log('Filtered Images:', images);  // Print the filtered images
        const imageUrls = await Promise.all(
          images.map(image => {
            console.log(image.key);
            return Storage.get(image.key, {level: 'private', expires: 86400})
              .then(signedUrl => {
                console.log(signedUrl); // This will print the signed URL of the current image.
                return signedUrl;
              });
          })
        );
        
        const sizes = await Promise.all(
          imageUrls.map((url) => {
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.onload = () => resolve({width: img.width, height: img.height});
              img.onerror = reject;
              img.src = url;
            });
          })
        );
  
        const [firstSize, ...restSizes] = sizes;
        for (let size of restSizes) {
          if (size.width !== firstSize.width || size.height !== firstSize.height) {
            setSameSize(false);

            //     alert("Images are not all the same size.");
        //     throw new Error("Images are not all the same size.");
          }
        }
        
        const updatedItemData = imageUrls.map((url, index) => ({
          img: url,
          title: images[index].key,
        }));
        
        setItemData(updatedItemData);
      } else {
        console.log("No images found");
      }
    } catch(error) {
      console.error('Error loading images: ', error);
    }
  }
  



  const onFileChange = async (e) => {
    const files = e.target.files;
    if (!files.length) return;
  
    for (let i = 0; i < files.length; i++) {
      await uploadFile(files[i]);
    }
  
    loadImages();  // Load images including the new ones
  };

  function RowRadioButtonsGroup() {
    return (
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={zoomValue}  // make the RadioGroup a controlled component
          onChange={handleZoomChange}  // call the handler when the radio button value changes
        >
          <FormControlLabel value="in" control={<Radio />} label="Zoom In" />
          <FormControlLabel value="out" control={<Radio />} label="Zoom Out" />
        </RadioGroup>
      </FormControl>
    );
  }
  

  async function downloadFile() {
    try {
      const fileName = zoomValue === 'in' ? 'zoom-in.mp4' : 'zoom-out.mp4';

      const signedUrl = await Storage.get(fileName, {level: 'private', expires: 86400});  
      console.log(signedUrl); // This will print the signed URL of the file.
  
      // Create a link to download the file
      const link = document.createElement('a');
      link.href = signedUrl;
      link.download = fileName;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file: ', error);
    }
  }


  async function handleClick() {
    try {
      const lambdaResponse = await callLambdaFunction();
      // Once the Lambda function is done, download the result from S3:
      console.log(lambdaResponse);
      if (lambdaResponse && lambdaResponse.Payload === "\"Done!\"") {
        await downloadFile();
      } else {
        console.error('Lambda function did not return Done!');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
  
  async function deleteAllImages() {
    try {
      const response = await Storage.list('', {level: 'private'}); // list from the root
      const deletePromises = response.results.map(file => Storage.remove(file.key, {level: 'private'}));
      await Promise.all(deletePromises);
      console.log("All files deleted successfully");
      setSameSize(true);
      setItemData([]); // Clear the list of images from the state
    } catch(error) {
      console.error('Error deleting all images: ', error);
    }
  }
  
  const handleAddPhototsClick = (e) => {
    if (!sameSize) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div className="App">
      <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
          Dead-Simple Zoomer
        </Typography>
        <Box sx={{ position: 'absolute', right: 15 }}> {/* Add flex-end to right align the buttons */}

          <Button color="inherit" onClick={signOut}>Sign out</Button>
        </Box>
      </Toolbar>
      </AppBar>
      </Box>
      <header className="App-header">
        <RowRadioButtonsGroup></RowRadioButtonsGroup>
        <input 
              type="file" 
              accept="image/*" 
              id="addphoto" 
              aria-describedby="tooltip-add-photo-id" 
              aria-label="Add a Photo" 
              onChange={onFileChange} 
              multiple
              style={{ display: 'none' }} 
            />
        <Box display="flex" flexDirection="row" justifyContent="center" gap={2} sx={{ width: '100%' }}>
          <label htmlFor="addphoto" style={{ display: 'flex', alignItems: 'center' }} onClick={handleAddPhototsClick}>
            <Button variant="contained" disabled={!sameSize} component="span">Add Photos</Button>
          </label>
          <Button variant="contained" aria-describedby="tooltip-clear-photos-id" id="clearphotos" disabled={itemData.length === 0} aria-label="Delete" onClick={deleteAllImages}>Restart</Button>
          <Button variant="contained" disabled={(itemData.length < 2) || (!sameSize)} onClick={handleClick}>Animate!</Button>
        </Box>
        <MyImageList itemData={itemData} sameSize={sameSize}></MyImageList>
      </header>
    </div>
  );
}

export default withAuthenticator(App);
